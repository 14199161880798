<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Teams from "@/services/Teams";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Rounds",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
        title: "Competition Rounds",
        items: [
            {
              text: "Fixtures",
              href: "/fixtures",
            },
            {
              text: "Rounds",
              active: true,
            },
        ],
        rounds: null,
        error: null,
        competition_id: process.env.VUE_APP_COMPETITION_ID,
        fields: [
          {
            key: 'teams',
            sortable: false,
            label: 'Match'
          },
          {
            key: 'result',
            sortable: true,
            label: 'Result'
          },
          {
            key: 'venue',
            sortable: true,
            label: 'Venue'
          },
          {
            key: 'featured',
            sortable: true,
            label: 'Featured Match'
          },
          {
            key: 'date_formated',
            sortable: true,
            label: 'Match Date'
          }
        ],
    };
  },
  middleware: "authentication",
  created() {
      this.competitionFixtures()
  },
  methods: {
      async competitionFixtures(){
          try {
              await Teams.competitionFixtures(this.competition_id)
              .then(response => {
                  this.rounds = response.data.data.rounds;
              })
              .catch(error => {
                  this.error = error.response.data.error ? error.response.data.error : "";
              })
          } catch (error) {
              this.error = error.response.data.error ? error.response.data.error : "Error";
          }
      },
  },
  
};
</script>

<template>
  <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
            <div class="card">
              <div class="card-body">

                <div role="tablist">
                    <b-card v-for="(item, index) in rounds" :key="item.round" no-body class="mb-1 shadow-none">
                        <b-card-header header-tag="header" role="tab"  header-bg-variant="info">
                          <h6 class="m-0">
                              <a  v-b-toggle="'accordion-'+index" class="text-dark" href="javascript: void(0);">
                                  <h6 class="text-light">Round {{index}}</h6>
                              </a>
                          </h6>
                        </b-card-header>
                         <b-collapse   :id="`accordion-${index}`"  :visible="item.status=='active'"   role="tabpanel" >
                            <b-card-body>
                                <b-card-text>
                                    <div>
                                        <b-table striped  :items="item.matches" :fields="fields" >
                                              <template #cell(teams)="data">
                                                  <div>
                                                      <img :src="data.item.home_team_logo" alt="" 
                                                        class="rounded me-2" :style="{ 'height': '1.5rem' , 'width':'1.5rem', 'float': 'left'  }"
                                                      >
                                                      <p class="inline-block">{{data.item.home_team_name}}</p>

                                                      <img :src="data.item.away_team_logo" alt="" 
                                                        class="rounded me-2" :style="{ 'height': '1.5rem' , 'width':'1.5rem', 'float': 'left'  }"
                                                      >
                                                      <p class="inline-block">{{data.item.away_team_name}}</p>
                                                  </div>
                                              </template>
                                              <template #cell(result)="data">
                                                  <p>{{data.item.score.formatted}}</p>
                                              </template>
                                              <template v-slot:cell(featured)="data">
                                                  <div v-if="data.item.featured" class="badge bg-pill bg-soft-danger font-size-14">featured</div>
                                              </template>
                                        </b-table>
                                    </div>
                                </b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>

              </div>
            </div>
          </div>
      </div>
  </Layout>
</template>
